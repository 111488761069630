import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Form, FormGroup } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import FormItem from '../../../../common/forms/FormItem';
import CabinCrewSelect from '../../../../common/forms/select/CabinCrewSelect';
import FormFooter from '../../../../common/forms/FormFooter';
import TextInput from '../../../../common/forms/input/TextInput';

const ItinerariesForCrewCabineForm = ({ handleSubmit }) => (
  <Form onSubmit={handleSubmit}>
    <FormGroup row>
      <FormItem label="Servicio a Bordo">
        <Field
          name="cabinCrewIdList"
          component={CabinCrewSelect}
          isClearable
          isMulti
        />
      </FormItem>
      <Field name="id" component={TextInput} type="hidden" />
    </FormGroup>
    <FormFooter />
  </Form>
);

ItinerariesForCrewCabineForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const formComponent = reduxForm({
  form: 'ItinerariesForCrewCabineForm',
})(ItinerariesForCrewCabineForm);

export default connect(null, null)(formComponent);
