import React from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { tzNormalizeDate } from '../../../../../utils/date';
import { DATE_TIME_FORMAT } from '../../../../../config/locale';

const ItineraryForSchedulingModal = ({ body, row }) => {
  const { departuretime, sourceLocationName, destinationLocationName } = row;

  return (
    <>
      <Row className="text-center">
        <Col sm={4} xs={6}>
          <p>
            <strong>Fecha</strong>
            <br />
            {tzNormalizeDate({
              date: departuretime,
              format: DATE_TIME_FORMAT,
            })}
          </p>
        </Col>
        <Col sm={4} xs={6}>
          <p>
            <strong>Origen</strong>
            <br />
            {sourceLocationName}
          </p>
        </Col>
        <Col sm={4} xs={12}>
          <p>
            <strong>Destino</strong>
            <br />
            {destinationLocationName}
          </p>
        </Col>
      </Row>
      {body}
    </>
  );
};

ItineraryForSchedulingModal.propTypes = {
  row: PropTypes.shape({
    departuretime: PropTypes.number.isRequired,
    sourceLocationName: PropTypes.string.isRequired,
    destinationLocationName: PropTypes.string.isRequired,
  }).isRequired,
  body: PropTypes.element.isRequired,
};

export default ItineraryForSchedulingModal;
