import React from 'react';
import PropTypes from 'prop-types';
import './ActionsCell.css';
import ConfirmButtonCell from './ConfirmButtonCell';
import ClearButtonCell from './ClearButtonCell';

const ActionsCell = ({
  handleConfirm,
  handleClear,
  data,
  id,
  showClearButton,
  showConfirmButton,
  confirmIcon,
}) => {
  const confirmButton = handleConfirm && (
    <ConfirmButtonCell
      handleConfirm={handleConfirm}
      data={data}
      id={id}
      showConfirmButton={showConfirmButton}
      icon={confirmIcon}
    />
  );
  const clearButton = handleClear && (
    <ClearButtonCell
      handleClear={handleClear}
      data={data}
      id={id}
      showClearButton={showClearButton}
    />
  );

  return (
    <div className="actionsContainer">
      {confirmButton} {clearButton}
    </div>
  );
};

ActionsCell.propTypes = {
  handleConfirm: PropTypes.func,
  handleClear: PropTypes.func,
  data: PropTypes.shape({}).isRequired,
  id: PropTypes.number.isRequired,
  showClearButton: PropTypes.bool,
  showConfirmButton: PropTypes.bool,
  confirmIcon: PropTypes.string,
};

ActionsCell.defaultProps = {
  handleConfirm: null,
  handleClear: null,
  showClearButton: true,
  showConfirmButton: true,
  confirmIcon: 'fa-plus-circle',
};

export default ActionsCell;
