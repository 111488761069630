import React from 'react';
import PropTypes from 'prop-types';

const ConfirmButtonCell = ({
  handleConfirm,
  data,
  id,
  showConfirmButton,
  icon,
}) =>
  showConfirmButton && (
    <>
      <i
        tabIndex="0"
        role="button"
        className={`fa text-success ${icon}`}
        onKeyPress={() => handleConfirm(data, id)}
        onClick={() => handleConfirm(data, id)}
        id={`btnConfirm${id}`}
      />
    </>
  );

ConfirmButtonCell.propTypes = {
  handleConfirm: PropTypes.func.isRequired,
  data: PropTypes.shape({}).isRequired,
  id: PropTypes.number.isRequired,
  showConfirmButton: PropTypes.bool,
  icon: PropTypes.string.isRequired,
};

ConfirmButtonCell.defaultProps = {
  showConfirmButton: true,
};

export default ConfirmButtonCell;
