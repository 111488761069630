import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import { clearItineraries } from '../../../../../actions';
import FormFooter from '../../../../common/forms/FormFooter';
import Table from '../../../../common/Table';
import { filterMethodCaseInsensitive } from '../../../../../utils/filter-methods';
import { generateItinerariesForSchedulingTableColumns } from '../../../../../config/columns';
import Modal from '../../../../common/modal/Modal';
import ItinerariesForSchedulingForm from './ItinerariesForSchedulingForm';
import {
  COLOR,
  TRIP_CIRCUIT_STATUS_OPTIONS,
} from '../../../../../config/constants';
import { orderBy } from '../../../../../utils/array';
import ItinerariesForCrewCabineForm from './ItinerariesForCrewCabineForm';
import ItineraryForSchedulingModal from './ItineraryForSchedulingModal';

const generateStyle = (row) => {
  let background = '';

  if (row) {
    const {
      original: { highligth, highligthCrewCabine },
    } = row;

    if (highligth || highligthCrewCabine) {
      background = COLOR.success;
    }
  }

  return { background };
};

const ItinerariesForSchedulingTable = ({
  onHandleBusAssignment,
  dispatchClearItineraries,
  loading,
  itineraries,
}) => {
  useEffect(() => () => dispatchClearItineraries(), []);

  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('Registro de Datos');
  const [modalBody, setModalBody] = useState(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [selectedData, setSelectedData] = useState([]);

  const didUpdate = () => {
    const content = itineraries.get('content') || [];

    const newContent = content.map((row) => ({
      ...row,
      highligth: false,
      highligthCrewCabine: false,
      companyBusId: row.companyBusId
        ? {
            value: null, // TODO remove when back send data
            label: row.companyBusId,
          }
        : null,
    }));

    if (newContent.length !== data.length) {
      const sortedData = orderBy(newContent, [
        'departuretime',
        'sourceLocationName',
        'destinationLocationName',
      ]);
      setData(sortedData);
      setOriginalData(sortedData);
      setSelectedData([]); // This line restores itineraries with assigned bus
    }
  };

  useEffect(() => {
    didUpdate();
  }, [itineraries]);

  const onShowModal = () => setShowModal(true);

  const onCloseModal = (index) => {
    // Hack to put focus in next confirm button
    // TODO remove when upgrade dependencies: reactstrap and react-table
    const buttonFocus = document.getElementById(
      `btnConfirm${index || selectedRowIndex}`,
    );
    if (buttonFocus) buttonFocus.focus();

    setShowModal(false);
    setSelectedRowIndex(null);
  };

  const getDataIndex = ({ id }) => data.findIndex((row) => row.id === id);

  const onSubmitForm = (formValues, index) => {
    const itineraryIndex = getDataIndex({
      id: formValues.id,
    });
    const newData = [...data];
    newData[itineraryIndex] = {
      ...newData[itineraryIndex],
      companyBusId: formValues.companyBusId,
      tripCircuitStatus: formValues.tripCircuitStatus,
      highligth: true,
    };
    setSelectedData([...selectedData, newData[itineraryIndex]]);
    setData(newData);
    onCloseModal(index + 1);
  };

  const onSubmitCrewCabineForm = (formValues, index) => {
    const itineraryIndex = getDataIndex({
      id: formValues.id,
    });

    const newData = [...data];
    newData[itineraryIndex] = {
      ...newData[itineraryIndex],
      cabinCrewList: formValues.cabinCrewIdList.map((cabinCrew) => ({
        id: cabinCrew.value,
        label: cabinCrew.label,
      })),
      highligthCrewCabine: true,
    };
    setSelectedData([...selectedData, newData[itineraryIndex]]);
    setData(newData);
    onCloseModal(index + 1);
  };

  const generateInitialValues = ({ tripCircuitStatus, id }) => ({
    tripCircuitStatus: tripCircuitStatus || TRIP_CIRCUIT_STATUS_OPTIONS[0],
    id,
  });

  const handleConfirm = (row, index) => {
    setSelectedRowIndex(index);

    const { passengerSeatCapacity } = row;

    const form = (
      <ItinerariesForSchedulingForm
        onSubmit={(rowElement) => onSubmitForm(rowElement, index)}
        initialValues={generateInitialValues(row)}
        passengerSeatCapacity={passengerSeatCapacity}
      />
    );

    const body = <ItineraryForSchedulingModal row={row} body={form} />;

    setModalTitle('Registro de Datos para Programar');
    setModalBody(body);
    onShowModal(true);
  };

  const generateCabinCrewInitialValues = ({ cabinCrewList, id }) => ({
    cabinCrewIdList: cabinCrewList.map((cabinCrew) => ({
      value: cabinCrew.cabinCrewId || cabinCrew.id,
      label: cabinCrew.cabinCrewName || cabinCrew.label,
    })),
    id,
  });

  const handleConfirmCabineCrew = (row, index) => {
    setSelectedRowIndex(index);

    const form = (
      <ItinerariesForCrewCabineForm
        onSubmit={(rowElement) => onSubmitCrewCabineForm(rowElement, index)}
        initialValues={generateCabinCrewInitialValues(row)}
      />
    );

    const body = <ItineraryForSchedulingModal row={row} body={form} />;
    setModalTitle('Registro de Datos para Programar');
    setModalBody(body);
    onShowModal(true);
  };

  const handleClear = (row) => {
    const index = getDataIndex(row);
    const newData = [...data];
    newData[index] = originalData[index];
    // drop row selected
    setSelectedData(
      selectedData.filter((rowElement) => rowElement.id !== newData[index].id),
    );
    setData(newData);
    setSelectedRowIndex(null);
  };

  const handleClearCabineCrew = (row) => {
    const index = getDataIndex(row);
    const newData = [...data];
    newData[index] = originalData[index];
    setSelectedData(
      selectedData.filter((rowElement) => rowElement.id !== newData[index].id),
    );
    setData(newData);
    setSelectedRowIndex(null);
  };

  const modal = (
    <Modal
      show={showModal}
      title={modalTitle}
      onClickClose={onCloseModal}
      body={modalBody}
      size="lg"
    />
  );

  const saveButtonDisabled =
    loading || !data.find((row) => row.highligth || row.highligthCrewCabine);

  return (
    <div className="mt-4">
      <Table
        manual={false}
        columns={generateItinerariesForSchedulingTableColumns({
          handleConfirm,
          handleClear,
          handleConfirmCabineCrew,
          handleClearCabineCrew,
        })}
        data={data}
        filterable
        defaultFilterMethod={filterMethodCaseInsensitive}
        loading={loading}
        generateStyle={generateStyle}
      />
      <div className="mt-3">
        <FormFooter
          saveButtonAction={() => onHandleBusAssignment(selectedData)}
          saveButtonDisabled={saveButtonDisabled}
          saveButtonText="Asignar buses"
          saveButtonType="button"
        />
      </div>
      {modal}
    </div>
  );
};

const mapStateToProps = ({ ItineraryUnit }) => ({
  loading: ItineraryUnit.Itinerary.getIn(['all', 'loading']),
  itineraries: ItineraryUnit.Itinerary.getIn(['all', 'content']),
});

const mapDispatchToProps = {
  dispatchClearItineraries: clearItineraries,
};

ItinerariesForSchedulingTable.propTypes = {
  loading: PropTypes.bool,
  dispatchClearItineraries: PropTypes.func.isRequired,
  onHandleBusAssignment: PropTypes.func.isRequired,
  itineraries: PropTypes.instanceOf(Immutable.Map).isRequired,
};

ItinerariesForSchedulingTable.defaultProps = {
  loading: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ItinerariesForSchedulingTable);
